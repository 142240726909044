<script setup>
    import PrimevueTag from 'primevue/tag';

    const props = defineProps({
        severity: {
            default: 'primary',
        },

        light: {
            type: Boolean,
            default: false,
        },
    });

    const classes = computed(() => {
        let classes = {};
        classes[`p-tag-${props.severity}`] = true;

        if (props.light) {
            classes['p-tag-light'] = true;
        }

        return classes;
    });
</script>

<template>
    <PrimevueTag :class="classes">
        <slot />
    </PrimevueTag>
</template>

<style lang="scss">
    .p-tag.p-tag-light {
        color: $text-primary;
        background: rgba($text-primary, 0.1);

        &.p-tag-success {
            color: $text-success;
            background: rgba($text-success, 0.1);
        }

        &.p-tag-info {
            color: $text-info;
            background: rgba($text-info, 0.1);
        }

        &.p-tag-warning {
            color: $text-warning;
            background: rgba($text-warning, 0.1);
        }

        &.p-tag-danger {
            color: $text-danger;
            background: rgba($text-danger, 0.1);
        }
    }
</style>
